import React from "react";

import ProfileWeapons from "@/game-apex/components/ProfileWeapons.jsx";
import type { ApexMatch } from "@/game-apex/models/match.mjs";

export default function MatchTileWeapons({
  match,
  profileId,
}: {
  match: ApexMatch;
  profileId: string;
}) {
  return <ProfileWeapons match={match} profileId={profileId} />;
}
